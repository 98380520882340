<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 256 256"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_28_32)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M128 0C134.427 0 139.636 5.20978 139.636 11.6364V116.364H244.364C250.79 116.364 256 121.573 256 128C256 134.427 250.79 139.636 244.364 139.636H139.636V244.364C139.636 250.79 134.427 256 128 256C121.573 256 116.364 250.79 116.364 244.364V139.636H11.6364C5.20978 139.636 0 134.427 0 128C0 121.573 5.20978 116.364 11.6364 116.364H116.364V11.6364C116.364 5.20978 121.573 0 128 0Z"
        fill="currentColor"
      />
    </g>
  </svg>
</template>
